<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划单工序合并后的列表
	开始时间：2021-08-05
	开发人员：刘巍骏,康泽鑫
	最后修改：2021-08-05
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview returnview" id="mes_processTableBox">
    <!-- 报价操作框 -->
    <div class="billingview_headoperate">
      <!-- 标题 -->
      <div class="head_operateList">
        <ul class="list condition">
          <li @click="getMesProcessList(0)" :class="{'selLi':sel_searchBtn==0}">
            全部
          </li>
          <!--<li @click="getMesProcessList(1)" :class="{'selLi':sel_searchBtn==1}">
            已审
          </li>
          <li @click="getMesProcessList(2)" :class="{'selLi':sel_searchBtn==2}">
            未审
          </li> -->
          <li @click="getMesProcessList(3)" :class="{'selLi':sel_searchBtn==3}">
            自产
          </li>
          <li @click="getMesProcessList(4)" :class="{'selLi':sel_searchBtn==4}">
            委外
          </li>
          <li>
            <el-popover placement="bottom-start" width="400" v-model="show_searchBox" trigger="manual">
              <div class="billingview_searchTitle">
                <span>根据以下条件搜索:</span>
              </div>
              <el-form ref="form" :model="offer_searchData" size="mini" label-width="100px">
                <el-form-item label="单号:">
                  <el-input v-model="offer_searchData.billingNumber" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="工序名:">
                  <el-input v-model="offer_searchData.processName" size="small" placeholder="请输入内容"
                    @focus="judgeRelatedData(4)" clearable></el-input>
                </el-form-item>
                <el-form-item label="生产单号:">
                  <el-input v-model="offer_searchData.proillingNumber" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                </el-form-item>
                <el-form-item label="供应商:">
                  <el-input v-model="offer_searchData.supplierName" size="small" placeholder="请输入内容"
                    @focus="judgeRelatedData(1)" clearable></el-input>
                </el-form-item>
                <el-form-item label="部门名:">
                  <el-input v-model="offer_searchData.departName" size="small" placeholder="请输入内容"
                    @focus="judgeRelatedData(2)" clearable></el-input>
                </el-form-item>
                <el-form-item label="货品名:">
                  <el-input v-model="offer_searchData.productName" size="small" placeholder="请输入内容"
                    @focus="judgeRelatedData(3)" clearable></el-input>
                </el-form-item>
                <el-form-item label="起止日期:">
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="offer_searchData.startTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                  <el-col class="line" :span="3" :push="1"> - </el-col>
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="offer_searchData.endTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item label="单据状态:">
                  <el-select v-model="offer_searchData.billsStatus" placeholder="请选择状态" style="width: 100%;" clearable>
                    <el-option label="未提交" value="0">未提交</el-option>
                    <el-option label="审核中" value="1">审核中</el-option>
                    <el-option label="已驳回" value="2">已驳回</el-option>
                    <el-option label="复审中" value="3">复审中</el-option>
                    <el-option label="已审核" value="4">已审核</el-option>
                    <el-option label="处理中" value="5">处理中</el-option>
                    <el-option label="已完成" value="6">已完成</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="工序状态:">
                  <el-select v-model="offer_searchData.processStatus" placeholder="请选择状态" style="width: 100%;"
                    clearable>
                    <el-option label="未开始" value="0">未开始</el-option>
                    <el-option label="进行中" value="1">进行中</el-option>
                    <el-option label="已驳回" value="2">质检中</el-option>
                    <el-option label="复审中" value="3">返工中</el-option>
                    <el-option label="已审核" value="4">已完成</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="领料状态:">
                  <el-select v-model="offer_searchData.materialStatus" placeholder="请选择状态" style="width: 100%;"
                    clearable>
                    <el-option label="未领料" value="0">未领料</el-option>
                    <el-option label="领料中" value="1">领料中</el-option>
                    <el-option label="已领料" value="2">已领料</el-option>
                  </el-select>
                </el-form-item>
                <!--<el-form-item label="入库状态:">
                  <el-select v-model="offer_searchData.depotStatus" placeholder="请选择状态" style="width: 100%;" clearable>
                    <el-option label="未入库" value="0">未入库</el-option>
                    <el-option label="入库中" value="1">入库中</el-option>
                    <el-option label="已入库" value="2">已入库</el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="付款状态:">
                  <el-select v-model="offer_searchData.payStatus" placeholder="请选择状态" style="width: 100%;" clearable>
                    <el-option label="未付款" value="0">未付款</el-option>
                    <el-option label="付款中" value="1">付款中</el-option>
                    <el-option label="已付款" value="2">已付款</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="备注:">
                  <el-input v-model="offer_searchData.remark" size="small" placeholder="请输入内容" clearable></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="billingview_searchBtnBox">
                    <el-button @click="getMesProcessList(5)" type="primary">搜索</el-button>
                    <el-button @click="show_searchBox=false">取消</el-button>
                  </div>
                </el-form-item>
              </el-form>
              <span slot="reference" class="billingview_searchBut" @click="show_searchBox=!show_searchBox">
                查询<i class="el-icon-search"></i>
              </span>
            </el-popover>
          </li>
          <li class="worker" v-if="filterPower && filterPower.departmentType!=3">
            <userPowerSelect :departmentPowerData="departmentPower" :userPowerType="userPowerType"
              @getSelectData="getSelectUserPowerData"></userPowerSelect>
          </li>
        </ul>
      </div>
      <!-- 操作列表 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="operateBills(0)">
            <i class="el-icon-plus"></i>新增
          </li>
          <li @click="operateBills(3)">
            <i class="el-icon-check"></i>提交
          </li>
          <li @click="operateBills(1)">
            <i class="el-icon-document-checked"></i>审批
          </li>
          <!-- <li @click="getMaterial">
            <i class="el-icon-plus"></i>领料
          </li> -->
          <li>
            <billExport :billsTypeSign="7" :billsModelData="billsModelData" :billsClassify="productType"
              :originBillData="processSelData"></billExport>
          </li>
        </ul>
      </div>
    </div>

    <!-- 生产产品显示表格 -->
    <div class="billingview_tablewrapper billing_table">
      <el-table ref="productionTableList" :data="productProcessData" :summary-method="getSummaries"
        @select="getSelectedProdData" @select-all="getSelectedAllData" height="100%" border show-summary
        style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="35">
          <template slot="header" slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <li v-for="elem in showListFiledData" v-if="elem.classify == -1 || elem.classify == productType">
                    <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                  </li>
                </ul>
              </div>
              <i class="el-icon-setting"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" fixed width="30"></el-table-column>
        <!-- 操作 -->
        <!-- <el-table-column label="操作" fixed width="90">
          <template slot-scope="scope">
            <div class="operate">
              <i class="el-icon-delete operate_icon" title="驳回" @click="judgeRejectBills(scope.row)"></i>
              <i class="el-icon-delete-solid operate_icon" title="作废" @click="judgeDeleteBills(scope.row)"></i>
              <i class="el-icon-edit-outline operate_icon" title="修改" v-if="scope.row.is_submit<4"
                @click="updateBillData(scope.row,1)"></i>
              <i class="el-icon-view operate_icon" title="预览" @click="updateBillData(scope.row,2)"></i>
            </div>
          </template>
        </el-table-column> -->
        <!-- 数据内容 -->
        <el-table-column prop="billsNo" label="工序单号" sortable width="150" v-if="showListFiledArr.includes(0)"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="span_pointer span_color_blue" title="点击查看" @click="showProDetail(scope.row)">{{scope.row.billsNo}}</span>
          </template>
          </el-table-column>
        <el-table-column prop="billsDate" label="单据日期" width="150" v-if="showListFiledArr.includes(1)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="工序名称" width="150" v-if="showListFiledArr.includes(2)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="关联生产单" width="150" v-if="showListFiledArr.includes(15)" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="setNumSpan"
              @click="getProcessRelevanceData(scope.row,0)">已关联{{scope.row.mesMainNumInfo.length}}个生产单</span>
          </template>
        </el-table-column>
        <el-table-column label="关联物料" width="150" v-if="showListFiledArr.includes(16)" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="setNumSpan"
              @click="getProcessRelevanceData(scope.row,1)">已关联{{scope.row.assignMaterialList.length}}种物料</span>
          </template>
        </el-table-column>
        <el-table-column prop="workNum" label="派工数量" width="80" v-if="showListFiledArr.includes(17)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="qualifiedNum" label="合格数量" width="80" v-if="showListFiledArr.includes(18)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="residueNum" label="剩余数量" width="80" v-if="showListFiledArr.includes(19)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="scrapNum" label="报废数量" width="80" v-if="showListFiledArr.includes(20)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="isSubmit" label="单据状态" width="80" v-if="showListFiledArr.includes(3)">
          <template slot-scope="scope">
            <el-button v-if="scope.row.isSubmit == 0" type="info" size="mini">未提交</el-button>
            <el-button v-if="scope.row.isSubmit == 1" type="warning" size="mini">审核中</el-button>
            <el-button v-if="scope.row.isSubmit == 2" type="danger" size="mini">已驳回</el-button>
            <el-button v-if="scope.row.isSubmit == 3" type="primary" size="mini">复审中</el-button>
            <el-button v-if="scope.row.isSubmit == 4" type="success" size="mini">已审核</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="processState" label="工序状态" width="80" v-if="showListFiledArr.includes(4)">
          <template slot-scope="scope">
            <el-button v-if="scope.row.processState == 0" type="info" size="mini">未开始</el-button>
            <el-button v-if="scope.row.processState == 1 && new Date().getTime()>new Date(scope.row.startTime).getTime()" type="primary" size="mini">进行中</el-button>
            <el-button v-if="scope.row.processState == 1 && new Date().getTime()<new Date(scope.row.startTime).getTime()" type="info" size="mini">未开始</el-button>
            <el-button v-if="scope.row.processState == 2" type="danger" size="mini">质检中</el-button>
            <el-button v-if="scope.row.processState == 3" type="danger" size="mini">返工中</el-button>
            <el-button v-if="scope.row.processState == 4" type="success" size="mini">已完成</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="materialState" label="领料状态" width="80" v-if="showListFiledArr.includes(5)">
          <!-- <template slot-scope="scope">
            <el-button v-if="scope.row.materialState == 0" type="danger" size="mini">未领料</el-button>
            <el-button v-if="scope.row.materialState == 1" type="primary" size="mini">领料中</el-button>
            <el-button v-if="scope.row.materialState == 2" type="success" size="mini">已领料</el-button>
          </template> -->
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.id" :stateKeyName="'materialStateGet'"
              :originBillState="scope.row.materialState" :originBillStateType="'7.3'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <el-table-column prop="payState" label="付款状态" width="80" v-if="showListFiledArr.includes(6)">
          <template slot-scope="scope">
            <el-button v-if="scope.row.payState == 0" type="danger" size="mini">未付款</el-button>
            <el-button v-if="scope.row.payState == 1" type="primary" size="mini">付款中</el-button>
            <el-button v-if="scope.row.payState == 2" type="success" size="mini">已付款</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="manufactureTypeName" label="生产类型" width="80" v-if="showListFiledArr.includes(23)"
          show-overflow-tooltip></el-table-column>
          <el-table-column prop="totalTime" label="合计用时" width="80" v-if="showListFiledArr.includes(21)"
            show-overflow-tooltip></el-table-column>
            <el-table-column prop="startTime" label="开始时间" width="180" v-if="showListFiledArr.includes(24)"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="endTime" label="结束时间" width="180" v-if="showListFiledArr.includes(25)"
              show-overflow-tooltip></el-table-column>
              <el-table-column prop="costTypeName" label="计费方式" width="120" v-if="showListFiledArr.includes(22)"
                show-overflow-tooltip></el-table-column>
                <el-table-column prop="userName" label="负责人" width="150" v-if="showListFiledArr.includes(13)"
                  show-overflow-tooltip></el-table-column>
                <el-table-column prop="deptName" label="负责部门" width="150" v-if="showListFiledArr.includes(14)"
                  show-overflow-tooltip></el-table-column>
                  <el-table-column prop="createUserName" label="制单人" width="100" v-if="showListFiledArr.includes(9)"
                    show-overflow-tooltip></el-table-column>
                  <el-table-column prop="createTime" label="制单时间" width="180" v-if="showListFiledArr.includes(10)"
                    show-overflow-tooltip></el-table-column>
                    <el-table-column prop="checkUserName" label="审核人" width="100" v-if="showListFiledArr.includes(11)"
                      show-overflow-tooltip></el-table-column>
                    <el-table-column prop="checkTime" label="审核时间" width="180" v-if="showListFiledArr.includes(12)"
                      show-overflow-tooltip></el-table-column>
         <el-table-column prop="totalLabourCost" label="工序金额" width="120" v-if="showListFiledArr.includes(8)"
          show-overflow-tooltip></el-table-column>
      </el-table>
    </div>

    <!-- 表格分页 -->
    <div class="billing_tablePage">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="pageData.currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size.sync="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageData.totalPage">
      </el-pagination>
    </div>

    <!-- 弹框 -->
    <!-- 工序关联生产单弹框 -->
    <el-dialog title="关联生产单" :visible.sync="show_processBills" width="800px" append-to-body>
      <!-- 生产单内容 -->
      <div class="processMaterialBox billing_table">
        <el-table :data="processBillData" height="100%" border style="width: 100%">
          <!-- 索引 -->
          <el-table-column type="index" width="35"></el-table-column>
          <!-- 数据内容 -->
          <el-table-column prop="mesMainNo" label="生产单号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="name" label="货品名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="product_code" label="货品编号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="specifications" label="货品规格" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="mesMainNum" label="生产数量" show-overflow-tooltip>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="show_processBills=false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 工序关联物料弹框 -->
    <el-dialog title="关联物料" :visible.sync="show_processMaterial" width="800px" append-to-body>
      <!-- 物料内容 -->
      <div class="processMaterialBox billing_table">
        <el-table ref="productionTableList" :data="processMaterialData" height="100%" border style="width: 100%">
          <!-- 索引 -->
          <el-table-column type="index" fixed width="35"></el-table-column>
          <!-- 数据内容 -->
          <el-table-column prop="productInfoCode" label="物料编号" width="150" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="productInfoName" label="物料名称" width="150" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="specifications" label="物料规格" width="150" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="needNum" label="所需数量" width="80" show-overflow-tooltip></el-table-column>
          <el-table-column prop="getNum" label="已领料数" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="setNumSpan" v-if="scope.row.getNum == scope.row.needNum">{{scope.row.getNum}}</span>
              <span class="setSomeNumSpan" v-else>{{scope.row.getNum}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="calculateName" label="计量单位" width="80" show-overflow-tooltip></el-table-column>
          <el-table-column prop="price" label="成本单价" width="80" show-overflow-tooltip></el-table-column>
          <el-table-column prop="remark" label="备注" min-width="180" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="show_processMaterial=false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增工序单组件 -->
    <productionplanProcess :showMargeProcess="showMargeProcess"></productionplanProcess>

    <!-- 新增单据组件 -->
    <nodeBills :nodeTypeCode="nodeTypeCode" :billsTypeSign="billsTypeSign" :billsClassify="inventoryType"
      :operateType="operateType" :generateProcessId="generateProcessId" :billsTypeDefaultCode="billsTypeDefaultCode"
      v-if="show_billsCreateBox"></nodeBills>

    <!-- 部门选择弹框 -->
    <departmentTree @SelectedData="getDepartmentData"></departmentTree>

    <!-- 供应商选择弹框 -->
    <supplierList @SelectedData="getSupplierData"></supplierList>

    <!-- 产品选择弹框 -->
    <productList @SelectedData="getProductData" :bomCondition="1" :isMultiple="0"></productList>

    <!-- 工序选择组件 -->
    <technologyList @SelectedData="getSelTechnologyData"></technologyList>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入接口方法
  import workApi from '@/network/system/workflowSetup.js'; //工作流程相关接口请求
  import api from '@/network/production/production.js'; //生产模块相关接口
  import apiDepart from '@/network/hr/organization.js'; //人力模块

  //导入组件
  import productionplanProcess from './productionplan_process.vue'; //新增工序单组件
  import nodeBills from '@/components/commComponent/billing/nodeBills'; //新建单据公共组件
  import departmentTree from '@/components/commComponent/tree/tree_department'; //部门选择组件
  import supplierList from '@/components/commComponent/list/list_supplier'; //供应商列表组件
  import productList from '@/components/commComponent/list/list_product'; //产品列表组件
  import technologyList from '@/components/commComponent/list/list_technology.vue'; //工序选择组件
  import userPowerSelect from '@/components/commComponent/power/user_power_select'; //用户权限查询组件
  import billExport from '@/components/commComponent/dialog/bill_export'; //单据打印组件
  import relvanceBillTipStrip from '@/components/commComponent/tooltip/relvanceBillTipStrip.vue'; //单据关联其它单据信息

  export default {
    name: 'productionProcessView',
    data() {
      return {
        //界面数据
        productProcessData: [], //工序单列表数据
        processMaterialData: [], //工序关联物料数据
        processBillData: [], //工序关联生产单数据
        processSelData: [], //当前选择的工序
        generateProcessId: "", //当前选择的id字符串数组
        curNodeTypeCode: 'SYS-NODE-TYPE-003', //单据分类(默认生产单)
        curBillsTypeSign: 7, //单据分类标签(7代表生产执行单)
        rejectData: '', //驳回原因
        deleteData: '', //删除原因
        showListFiledData: [], //列表可显示的字段
        showListFiledArr: [], //列表需要显示的


        //控制开关
        show_processBills: false, //控制显示工序关联生产单弹框
        show_processMaterial: false, //控制显示工序关联物料弹框
        show_searchBox: false, //是否显示搜索
        show_reject: false, //控制驳回弹框
        show_delete: false, //控制作废弹框


        //辅助数据
        sel_searchBtn: 0, //当前选中查询条件(0:全部 1:已审 2:未审 3:状态 4:高级查询)

        //分页数据
        pageData: {
          currentPage: 1, //当前页
          totalPage: 100, //总页数
          pageSize: 20 //页面大小
        },

        //子组件数据(生成领料出库单)
        nodeTypeCode: 'SYS-NODE-TYPE-004', //单据分类(默认出库单)
        billsTypeSign: 2, //单据分类标签(2代表出入库)
        inventoryType: 1, //出库单(领料单)
        operateType: 0, //操作类型(默认新增)
        billsTypeDefaultCode: 'DOC-M-014', //默认单据类型code(生产领料单)

        //工序单相关
        showMargeProcess: false, //控制是否显示工序单

        // 高级搜索框数据
        offer_searchData: { //高级搜索数据
          billingNumber: '', //单号
          processName: '', //工序名
          processId: '', //工序id
          proillingNumber: '', //生产单号
          supplierName: '', //供应商
          deptId: '', //部门id
          supplierId: '', //供应商id
          departName: '', //部门名
          productName: '', //货品名称
          productId: '', //货品id
          materialStatus: '', //领料状态
          depotStatus: '', //入库状态
          payStatus: '', //付款状态
          startTime: '', //开始时间
          endTime: '', //结束时间
          billsStatus: '', //单据状态
          processStatus: '', //工序状态
          collectStatus: '', //收款状态
          invoiceStatus: '', //发票状态
          outStatus: '', //出库状态
          remark: '' //备注
        },
        departmentPower: [], //权限部门
        filterPower: {}, //符合条件的权限对象
        billsModelData: [], //单据模板
        personnel: -1, //员工
        //用户查询权限子组件
        userPowerType: 1, //默认全公司
      }
    },
    created() {
      //初始化vuex所需数据
      this.initVuextData();
      //初始化列表显示字段数据
      this.initListShowFiledData();
    },
    mounted() {},
    updated() {
      this.$nextTick(() => {
        //表格加载处理
        this.$refs['productionTableList'].doLayout();
      });
    },
    computed: {
      ...mapState({
        show_billsCreateBox: state => state.billing.show_billsCreateBox, //控制显示新建弹框组件
        userPowerInfo: state => state.user.userPowerInfo, //权限
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        personnelData: state => state.hr.personnelData, //当前企业所有员工
        ManufactureType: state => state.system.ManufactureType, //生产类型
        companyparamlist: state => state.companyparamlist, //获取企业级参数
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        mesProcessListFileData: state => state.bill_list_config.mesProcessListFileData, //生产执行列表可显示字段
      }),
      //路由传入的参数(生产单据类型(0:生产计划单  1:生产执行单))
      productType() {
        //路由获取的参数需要转换为int类型
        return Number(this.$route.query.productType);
      },
      //路由传入的默认单据类型code
      productBillCode() {
        return this.$route.query.productBillCode;
      },
    },
    watch: {
      // 监听显示列表数据发生变化
      showListFiledData: {
        handler(newVal) {
          this.getCurShowFiledArr();
        },
        deep: true
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_BILLSCREATEBOX', //显示新建单据弹框
        'SHOW_SUPPLIERBOX', //控制供应商弹框是否显示
        'SHOW_DEPARTMENTBOX', //控制部门弹框是否显示
        'SHOW_PRODUCTBOX', //控制货品是否显示
        'SHOW_TECHNOLOGYBOX', //控制工序选择弹框是否显示
      ]),
      ...mapActions([
        'getAllUserData', //请求企业用户数据
        'getAllManufactureType', //生产类型
        'getUserPowerInfo', //获取用户操作权限
        'getelectronicTemplate', //获取所有单据类型
        // 'getFirstLevelDeptUser', //发送获取一级部门信息请求
      ]),
      ...mapMutations({
        getcompanyparamlist: 'getcompanyparamlist',
      }),
      /* 初始化界面所需数据vuex数据 */
      async initVuextData() {
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_processTableBox", 4, '列表初始化中,请稍后...');
        //判断用户权限
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
        }
        //判断单据模板数据是否存在
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }
        //判断生产类型是否存在
        if (this.ManufactureType.length == 0) { //不存在则请求数据
          await this.getAllManufactureType();
        }
        //获取单据类型数据
        this.getBillTemplateData();
        //获取用户查询权限权限范围
        await this.getUserPower();
        this.loading.close();
        //获取列表页数据
        this.getMesProcessList(0);
      },

      /* 获取单据类型 */
      async getBillTemplateData() {
        //判断单据模板数据是否存在
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }
        if (this.billTemplateData.length != 0) {
          //根据分类获取单据分类信息
          this.billsModelData = this.commonJsExtend.getBillsClassifyDataByType(2, this.curNodeTypeCode, this
            .billTemplateData).children;
          //根据code设置默认值
          if (!!this.productBillCode) {
            //判断是否为订单类型(查询所有订单)
            if (this.productBillCode == 'DOC-M-016') { //生产执行单查询所有单据类型
              this.offer_searchData.billsType = '';
            } else {
              this.offer_searchData.billsType = this.commonJsExtend.getBillsDataByType(2, this.productBillCode, this
                .billTemplateData).id
            }
          }
        }
      },

      /* 获取用户权限 */
      async getUserPower() {
        //判断是否有用户权限数据
        if (this.userPowerInfo) {
          this.filterPower = this.userPowerInfo.filter(item => item.parentLevel == 16 && item.childrenLevel == 9)[
            0] //获取符合条件的权限对象
          if (this.filterPower) {
            let departments = []
            let data = {}
            //获取权限部门类型
            this.userPowerType = parseInt(this.filterPower.departmentType);
            //判断权限部门
            if (this.filterPower.departmentType == 0) { //本部门
              departments.push(this.UserInfo.dept_id) //传入当前登录用户部门id
              data.deptId = departments.join(',')
              data.isAdmin = 0
            } else if (this.filterPower.departmentType == 1) { //全公司
              data.deptId = ''
              data.isAdmin = 1
            } else if (this.filterPower.departmentType == 2) { //指定部门
              departments.push(this.filterPower.departmentId) //传入部门id
              data.deptId = departments.join(',')
              data.isAdmin = 0
            } else if (this.filterPower.departmentType == 3) { //仅个人
            }
            await apiDepart.findFirstLevelDeptUser(data).then(res => {
              if (res.code == 200) {
                this.departmentPower = res.data.departmentList; //赋值并倒叙
              }
            })
          }
        }
      },

      /* 获取工序单列表页数据 */
      getMesProcessList(type, isUser) {
        let data = {
          pageIndex: this.pageData.currentPage, //当前页
          pageSize: this.pageData.pageSize, //页面大小
          processType: 1, //(0:生产单工序   1:合并后的工序主表)
        }
        //设置查询按钮选中
        this.sel_searchBtn = type;
        let {
          billingNumber, //单号
          processName, //工序名称
          processId, //工序id
          proillingNumber, //生产单号
          supplierName, //供应商
          departName, //部门名
          deptId, //部门id
          supplierId, //供应商id
          productName, //货品名
          productId, //货品id
          materialStatus, //领料状态
          depotStatus, //入库状态
          payStatus, //付款状态
          startTime, //开始时间
          endTime, //结束时间
          billsStatus, //单据状态
          processStatus, //工序状态
          remark //备注
        } = this.offer_searchData

        //判断查询类型
        if (type == 0) { //全部
        } else if (type == 1) { //已审
          data.isSubmit = 4
        } else if (type == 2) { //未审
          data.isSubmit = 1
        } else if (type == 3) { //自产
          let id = this.commonJsExtend.getDictMatchingDataByCode('DICT-SMM-001', this.ManufactureType).id
          data.manufactureTypeId = id
        } else if (type == 4) { //委外
          let id = this.commonJsExtend.getDictMatchingDataByCode('DICT-SMM-002', this.ManufactureType).id
          data.manufactureTypeId = id
        } else if (type == 5) { //高级条件查询
          this.show_searchBox = false
        } else if (type == 6) { //根据员工查询
          data.createUserId = this.personnel
        }
        //判断当前员工查询权限
        if (this.userPowerType == 3) { //仅个人
          data.createUserId = this.UserInfo.user_id;
        } else { //全公司,本部门,指定部门
          //判断是否查询全部
          console.log(this.personnel);
          if (this.personnel == -1) { //查询全部
            let userArr = [];
            this.departmentPower.forEach((itemI, indexI) => {
              if (itemI.userList) {
                itemI.userList.forEach((itemJ, indexJ) => {
                  userArr.push(itemJ.user_id);
                })
              }
            })
            data.createUserId = userArr.join(",");
          } else {
            data.createUserId = this.personnel.join(",");
          }
        }
        if ((startTime != '' && startTime != null) || (endTime != '' && endTime != null)) { //起止时间其中一个不为空
          if (startTime == '' || endTime == '') { //起止时间其中一个为空
            this.$message({
              type: 'warning',
              message: '查询条件为起止时间时必须两个都填写!',
              duration: this.elDuration
            })
            return
          }
        }

        //设置查询条件(单按钮查询不需要带高级查询条件)
        if (![0, 1, 2, 3, 4].includes(type)) {
          if (billingNumber != '') { //查询条件为 单号
            data.billsNo = billingNumber
          }
          if (proillingNumber != '') { //查询条件为 生产单号
            data.mesMainNo = proillingNumber
          }
          if (supplierName != '') { //查询条件为 供应商
            data.supplierId = supplierId
          }
          if (processName != '') { //查询条件为 工序名
            data.name = processName
          }
          if (departName != '') { //查询条件为 部门名
            data.deptId = deptId
          }
          if (productName != '') { //查询条件为 货品名
            data.product_info_id = productId
          }
          if (startTime != '') { //查询条件为 开始时间
            data.startTime = startTime
          }
          if (endTime != '') { //查询条件为 结束时间
            data.endTime = endTime
          }
          if (billsStatus != '') { //查询条件为 单据状态
            data.isSubmit = billsStatus
          }
          if (materialStatus != '') { //查询条件为 领料状态
            data.materialState = materialStatus
          }
          if (processStatus != '') { //查询条件为 工序状态
            data.processState = processStatus
          }
          if (payStatus != '') { //查询条件为 付款状态
            data.payState = payStatus
          }
          if (remark != '') { //查询条件为 备注
            data.remark = remark
          }
        }

        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_processTableBox", 4, '数据更新中,请稍后...');
        //发送请求
        api.findMesProcessByCondition(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取列表页数据
            this.productProcessData = res.data.rows;
            //获取分页数据
            this.pageData.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      //跳转到生产详细工序页面
      showProDetail(data){
        this.$router.push({
          path: '/productionplan_details',
          query: {
            mesId: data.mesMainId,
            mesproDetailTab:2
          },
        })
      },
      getRelvanceDetail(){
        
      },
      /* 操作单据type(0:新增 1:审批  2:打印(已废) 3:提交) */
      operateBills(type) {
        if (type != 0) {
          // 判断是否可以处理
          if (this.processSelData.length == 0) { //判断为空
            this.$message({
              type: 'warning',
              message: '请选择需要处理的数据!',
              duration: this.elDuration
            })
            return
          }
        }
        // 获取需要处理的id数组
        let processIdArr = [];
        let isSubmitArr = [];
        this.processSelData.forEach((item, index) => {
          processIdArr.push(item.id);
          isSubmitArr.push(item.isSubmit)
        })
        //获取id字符串数组
        this.generateProcessId = processIdArr.join(",");
        let billingName = this.commonJsExtend.getBillsDataByType(2, 'DOC-M-016', this.billTemplateData)
          .bills_type //获取单据类型名称
        if (type == 0) { //新增
          this.showMargeProcess = true;
        } else if (type == 1) { //审批
          // 定义可审核状态
          let deleteState = [1, 3] //1:审核中  3:复审中
          // 判断该数据是否可以提交
          let isPass = true
          isSubmitArr.forEach(item => {
            if (deleteState.indexOf(item) == -1) {
              this.$message({
                type: 'warning',
                message: '选中数据中存在不可审核状态',
                duration: this.elDuration
              })
              return isPass = false
            }
          })
          if (!isPass) {
            return
          }
          // 审核确认提示框
          this.$confirm('您确认审核该单据吗,审核后的单据无法修改和作废?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            //定义传入后端的值
            let submitData = {
              //基本数据
              billingName, //单据类型名称
              mesProcessIds: this.generateProcessId, //生产执行单id
              processId: 313,
              statusId: 84, //节点id
            }
            api.updateMesProcessStatusYes(submitData).then(res => {
              if (res.code == 200) {
                this.getMesProcessList(this.sel_searchBtn) //刷新工序单列表页数据
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消审核',
              duration: this.elDuration
            });
          });
        } else if (type == 3) { //提交
          //定义可提交的状态
          let deleteState = [0, 2] //0:未提交  2:已驳回
          // 判断该数据是否可以提交
          let isPass = true
          isSubmitArr.forEach(item => {
            if (deleteState.indexOf(item) == -1) {
              this.$message({
                type: 'warning',
                message: '选中数据中存在不可提交状态',
                duration: this.elDuration
              })
              return isPass = false
            }
          })
          if (!isPass) {
            return
          }
          //定义传入后端的值
          let submitData = {
            billingName, //单据类型名称
            isSubmit: 1, //状态
            mesProcessIds: this.generateProcessId, //生产执行单id
            processId: 313,
            statusId: 84, //节点id
          }
          api.updateMesProcessSubmit(submitData).then(result => {
            if (result.code == 200) {
              this.getMesProcessList(this.sel_searchBtn) //刷新工序单列表页数据
            } else {
              this.$message({
                type: 'error',
                message: result.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 获取用户权限组件选择出来的数据 */
      getSelectUserPowerData(data) {
        // 判断是否为全部查询
        if (data[0] == -1) { //全部查询
          this.personnel = -1;
        } else { //非全部查询
          this.personnel = data;
        }
        //调用查询方法
        this.getMesProcessList(0);
      },

      /* 弹框组件 */
      judgeRelatedData(type) {
        if (type == 1) {
          this.SHOW_SUPPLIERBOX(true); //供应商弹框
        } else if (type == 2) {
          this.SHOW_DEPARTMENTBOX(true); //部门
        } else if (type == 3) {
          this.SHOW_PRODUCTBOX(true) //货品
        } else if (type == 4) {
          this.SHOW_TECHNOLOGYBOX(true) //工序
        }
      },


      /* 获取所选择的部门数据 */
      getDepartmentData(data) {
        let result = this.commonJsExtend.getDeptMsg(data);
        this.offer_searchData.departName = result.nameStr; //部门
        this.offer_searchData.deptId = result.idStr; //部门id
        this.$forceUpdate()
      },

      /* 获取所选择的供应商 */
      getSupplierData(data) {
        this.offer_searchData.supplierName = data.fullName; //供应商
        this.offer_searchData.supplierId = data.id; //供应商id
      },

      /* 获取产品已选数据 */
      getProductData(data) {
        if (data && data.length == 0) {
          return
        }
        //判断选择产品数
        if (data && data.length > 1) {
          this.$message({
            type: 'warning',
            message: '只能选择一个产品!',
            duration: this.elDuration
          })
          return
        }
        this.offer_searchData.productName = data[0].name //货品名称
        this.offer_searchData.productId = data[0].id //货品id
      },

      /* 获取工序弹框选择数据 */
      getSelTechnologyData(data) {
        if (data && data.length == 0) {
          return
        }
        //判断选择产品数
        if (data && data.length > 1) {
          this.$message({
            type: 'warning',
            message: '只能选择一个工序!',
            duration: this.elDuration
          })
          return
        }
        this.offer_searchData.processName = data[0].name
      },


      /* 显示工序关联弹框 (type=> 0:关联生产单 1:关联物料) */
      getProcessRelevanceData(data, type) {
        if (type == 0) { //关联生产单
          //获取数据
          this.processBillData = data.mesMainNumInfo;
          //显示弹框
          this.show_processBills = true;
          // console.log(data)
        } else if (type == 1) { //关联物料
          //获取数据
          this.processMaterialData = data.assignMaterialList;
          //显示弹框
          this.show_processMaterial = true;
        }
      },


      /* 打印单据 */
      printBills(isSubmitArr) {
        // 判断是否可以处理
        if (this.processSelData.length == 0) { //判断为空
          this.$message({
            type: 'warning',
            message: '请选择需要处理的数据!',
            duration: this.elDuration
          })
          return
        }

        //判断是否需要审核后才能打印
        let par_val = this.companyparamlist.param340
        if (par_val == 0) {

          if (this.SelelctData[0].depotMain.isSubmit < 4) {
            this.$message({
              type: 'warning',
              message: '单据必须审核后才能打印',
              duration: this.elDuration
            })
            return
          }
        }

        // 获取需要处理的id数组
        let processIdArr = [];
        this.processSelData.forEach((item, index) => {
          processIdArr.push(item.id);
        })
        //获取id字符串数组
        this.generateProcessId = processIdArr.join(",");


      },


      /* 工序领料 */
      getMaterial() {
        // 判断是否可以处理
        if (this.processSelData.length == 0) { //判断为空
          this.$message({
            type: 'warning',
            message: '请选择需要处理的数据!',
            duration: this.elDuration
          })
          return
        }
        // 获取需要处理的id数组
        let processIdArr = [];
        this.processSelData.forEach((item, index) => {
          processIdArr.push(item.id);
        })
        //获取id字符串数组
        this.generateProcessId = processIdArr.join(",");
        //设置生成的单据code
        this.nodeTypeCode = 'SYS-NODE-TYPE-004';
        //设置传入子组件的销售单据分类为出库单
        this.billsClassify = 1;
        //设置单据类型标签
        this.billsTypeSign = 7;
        //显示新增单据弹框
        this.SHOW_BILLSCREATEBOX(true)
      },

      /* 获取当前选择的工序 */
      getSelectedProdData(selection, row) {
        this.processSelData = selection;
      },

      /* 全选时获取工序 */
      getSelectedAllData(selection, row) {
        this.processSelData = selection;
      },

      /*初始化列表显示字段数据 */
      initListShowFiledData() {
        //获取界面显示数组
        this.showListFiledArr = this.commonJsExtend.initListShowFiledData('mesProcessListArr', this
            .mesProcessListFileData)
          .showListFiledArr;
        //获取控制数组
        this.showListFiledData = this.commonJsExtend.initListShowFiledData('mesProcessListArr', this
            .mesProcessListFileData)
          .showListFiledData;
      },

      /* 获取当前需要显示的列表字段 */
      getCurShowFiledArr() {
        //将数据存入cookie
        this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('mesProcessListArr', this.showListFiledData);
      },

      /* 分页数据大小改变 */
      handleSizeChange(val) {
        //获取分页大小
        this.pageData.pageSize = val;
        //请求数据
        this.getMesProcessList(this.sel_searchBtn)
      },

      /* 当前页改变 */
      handleCurrentChange(val) {
        //获取当前页
        this.pageData.currentPage = val;
        //请求数据
        this.getMesProcessList(this.sel_searchBtn)
      },

      /* 关闭合并工序弹框 */
      closeMargeBox() {
        this.showMargeProcess = false;
      },

      /* 获取合计数据 */
      getSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计';
            return;
          }
          let sumPropertys = ['totalAmount', 'totalTaxAmount', 'exportNum', 'printNum'];//定义需要计算的字段
          const values = data.map(item => Number(item[column.property]));
          if (sumPropertys.indexOf(column.property) != -1) {
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += '';
            } else {
              sums[index] = '';
            }
          }
        });
        return sums;
      }
    },
    components: {
      nodeBills,
      productionplanProcess,
      departmentTree,
      supplierList,
      productList,
      technologyList,
      userPowerSelect,
      billExport,
      relvanceBillTipStrip,
    }
  }
</script>
<style lang="less">
  //已设数据提示信息
  .setNumSpan {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: @theme;
  }

  //已设数据提示信息(未设完全)
  .setSomeNumSpan {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: red;
  }

  // 工序关联物料弹框
  .processMaterialBox {
    width: 100%;
    height: 40vh;
    overflow: auto;
  }
</style>
<style lang="less">
  .condition {
    li:not(.worker) {
      padding-top: 10px !important;
    }
  }
</style>
