<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新增工序合并界面
	开始时间：2021-07-31
	开发人员：刘巍骏,康泽鑫
	最后修改：2021-08-06
	备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <el-dialog :visible.sync="showMargeProcess" width="30%" :before-close="closeMargeProcessBox" :fullscreen="true"
      :show-close="false" id="mes_processMergeBox">
      <!-- dialog头部自定义 -->
      <div slot="title" class="processMargeDialog">
        <!-- 左边标题 -->
        <span class="title">工序合并</span>
        <!-- 提示内容 -->
        <el-tooltip placement="bottom-start">
          <div slot="content">
            <ul>
              <li>1.生产合并:可将多张生产相同的货品的生产计划单所有工序合并进行。</li>
              <li>2.工序合并:只将所选生产单中的指定工序合并进行。</li>
            </ul>
          </div>
          <i class="el-icon-info"></i>
        </el-tooltip>
      </div>

      <!-- 工序合并框内容 -->
      <div class="margeBoxAll">
        <!-- 上部分操作框 -->
        <div class="margeBoxTop">
          <!-- 左边操作方式 -->
          <div class="margeBoxLeft">
            <el-tabs type="border-card" v-model="operateType">
              <el-tab-pane label="生产合并" name="0">
                <!-- 生产合并内容 -->
                <div class="operateItemBox">
                  <!-- 列表 -->
                  <div class="operateList billing_table scollStyle">
                    <el-table :data="productBillData" height="100%" border style="width: 100%">
                      <!-- 操作 -->
                      <el-table-column label="" width="30">
                        <template slot-scope="scope">
                          <el-radio :label="scope.$index" v-model="selSupplyProductIndex"
                            @change.native="getSelectedSupply(scope.$index,scope.row,0)">{{''}}</el-radio>
                        </template>
                      </el-table-column>
                      <el-table-column prop="bills_no" label="生产单号" width="180" sortable></el-table-column>
                      <el-table-column prop="bills_date" label="单据日期" width="150"></el-table-column>
                      <el-table-column prop="productInfo.name" label="生产货品" width="150"></el-table-column>
                      <el-table-column prop="productInfo.specifications" label="规格型号" width="150"></el-table-column>
                      <el-table-column prop="plan_num" label="生产数量" width="80"></el-table-column>
                      <el-table-column prop="create_user_name" label="制单人" width="150"></el-table-column>
                    </el-table>
                  </div>
                  <!-- 分页 -->
                  <div class="operatePage">
                    <el-pagination @size-change="getOperatePageSize($event,0)"
                      @current-change="getOperateCurrentPage($event,0)" :current-page="operateProduct.currentPage"
                      :page-sizes="[10, 20, 30, 40, 50]" :page-size="operateProduct.pageSize"
                      layout="total, sizes, prev, pager, next, jumper" :total="operateProduct.totalPage">
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="工序合并" name="1">
                <!-- 工序合并内容 -->
                <div class="operateItemBox">
                  <!-- 列表 -->
                  <div class="operateList billing_table">
                    <el-table :data="productProcessData" height="100%" border style="width: 100%">
                      <!-- 操作 -->
                      <el-table-column label="" width="30">
                        <template slot-scope="scope">
                          <div class="operate">
                            <el-radio :label="scope.$index" v-model="selSupplyProcessIndex"
                              @change.native="getSelectedSupply(scope.$index,scope.row,1)">{{''}}</el-radio>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="name" label="工序名称" width="150" sortable show-overflow-tooltip></el-table-column>
                      <el-table-column prop="dept_name" label="负责部门" width="150" show-overflow-tooltip></el-table-column>
                      <el-table-column prop="user_name" label="负责人" width="160" show-overflow-tooltip></el-table-column>
                      <el-table-column prop="manufactureTypeName" label="生产类别" width="80" show-overflow-tooltip></el-table-column>
                      <el-table-column prop="cost_type_name" label="计费方式" width="80" show-overflow-tooltip></el-table-column>
                      <el-table-column prop="remark" label="备注" min-width="80" show-overflow-tooltip></el-table-column>
                    </el-table>
                  </div>
                  <!-- 分页 -->
                  <div class="operatePage">
                    <el-pagination @size-change="getOperatePageSize($event,1)"
                      @current-change="getOperateCurrentPage($event,1)" :current-page="operateProcess.currentPage"
                      :page-sizes="[10, 20, 30, 40, 50]" :page-size="operateProcess.pageSize"
                      layout="total, sizes, prev, pager, next, jumper" :total="operateProcess.totalPage">
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>

          <!-- 右边操作方式结果 -->
          <div class="margeBoxRight">
            <!-- 头部提示信息 -->
            <div class="rightHead">
              <h5 class="titleTip">符合条件的生产单</h5>
            </div>
            <!-- 符合条件的列表 -->
            <div class="rightBody billing_table scollStyle">
              <el-table :data="productBillAccord" @select="getSelNeedProductBill" @select-all="getSelNeedProductBillAll"
                height="100%" border style="width: 100%">
                <!-- 操作 -->
                <el-table-column type="selection" width="35"></el-table-column>
                <el-table-column prop="bills_no" label="生产单号" width="180" sortable></el-table-column>
                <el-table-column prop="bills_date" label="单据日期" width="150"></el-table-column>
                <el-table-column prop="productInfo.name" label="生产货品" width="150"></el-table-column>
                <el-table-column prop="productInfo.specifications" label="规格型号" width="150"></el-table-column>
                <el-table-column prop="plan_num" label="生产数量" width="80"></el-table-column>
                <el-table-column prop="create_user_name" label="制单人" width="120"></el-table-column>
                <el-table-column label="已合并" width="80">
                  <template slot-scope="scope">
                    <el-tooltip effect="dark" placement="left" :disabled="!getMergeNum(scope.row,0,1)">
                      <div slot="content">
                        <span v-for="item in getMergeNum(scope.row,0,1)">{{item}}<br /></span>
                      </div>
                      <span class="merge">{{getMergeNum(scope.row,1,1)}}</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column label="未合并" width="80">
                  <template slot-scope="scope">
                    <el-tooltip effect="dark" placement="left" :disabled="!getMergeNum(scope.row,0,0)">
                      <div slot="content">
                        <span v-for="item in getMergeNum(scope.row,0,0)">{{item}}<br /></span>
                      </div>
                      <span class="merge">{{getMergeNum(scope.row,1,0)}}</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <!-- 下部分列表 -->
        <div class="margeBoxContent">
          <!-- 提示信息 -->
          <div class="contentHead">
            <h5 class="titleTip">合并生产清单</h5>
          </div>
          <!-- 表格内容 -->
          <div class="contentBody billing_table scollStyle">
            <el-table :data="productSelData" border height="100%" style="width: 100%">
              <el-table-column prop="selProductBill.bills_no" label="生产单号" width="180" sortable></el-table-column>
              <el-table-column prop="selProductBill.bills_date" label="单据日期" width="150"></el-table-column>
              <el-table-column prop="selProductBill.productInfo.name" label="生产货品" width="180"></el-table-column>
              <el-table-column prop="selProductBill.plan_num" label="计划生产数量" width="100"></el-table-column>
              <el-table-column prop="selProcess.name" label="工序名称" width="180"></el-table-column>
              <el-table-column prop="selMaterial.productInfoName" label="物料" width="150"></el-table-column>
              <el-table-column prop="selMaterial.productInfoCode" label="物料编号" width="150"></el-table-column>
              <el-table-column prop="selMaterial.needNum" label="数量" width="80"></el-table-column>
              <el-table-column prop="selMaterial.calculateName" label="单位" width="80"></el-table-column>
              <!-- <el-table-column prop="get_num" label="领料量" width="80"></el-table-column>
              <el-table-column prop="address" label="损耗量" width="80"></el-table-column> -->
              <el-table-column prop="remark" label="备注" min-width="80"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 工序框操作 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="mergeProcessData(1)">提交审批</el-button>
        <el-button type="success" @click="mergeProcessData(2)">保存并审核</el-button>
        <el-button @click="closeMargeProcessBox">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  // 导入后端相关接口
  import productApi from '@/network/production/production.js'; //生产模块相关接口

  export default {
    name: 'productionplanProcess',
    props: {
      //控制工序合并弹框是否显示
      showMargeProcess: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        // 操作方式相关数据
        operateType: "0", //操作类型(0:生产单合并  1:工序合并)
        productBillData: [], //供选择的生产单列表数据
        selSupplyProduct: {}, //当前选中的供选择生产单数据
        selSupplyProductIndex: -1, //当前选中的供选择生产单数据
        productProcessData: [], //供选择的工序列表数据
        selSupplyProcess: {}, //当前被选中的供选择工序数据
        selSupplyProcessIndex: -1, //当前被选中的供选择工序数据下标

        // 分页数据
        operateProduct: { //供选择的生产计划单分页数据
          currentPage: 1, //当前页
          pageSize: 20, //页面大小
          totalPage: 0, //总页数
        },
        operateProcess: { //供选择的工序分页数据
          currentPage: 1, //当前页
          pageSize: 20, //页面大小
          totalPage: 0, //总页数
        },

        //符合条件的生产单相关数据
        productBillAccord: [], //符合条件的列表数据

        //下面所选择的生产单
        productSelData: [], //所选择的生产单列表
        processData: [], //所选择的工序

        //辅助数据
        loading: '', //loading框
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
      }),
    },
    mounted() {
      // 初始化界面所需参数(vuex数据)
      this.initVuextData();
    },
    watch: {
      //监听是否显示工序单合并界面
      showMargeProcess(newVal) {
        if (!newVal) {
          Object.assign(this.$data, this.$options.data());
        }
      },
      // 监听操作方式切换
      operateType(newVal) {
        //清空数据
        this.processData = [];
        this.productSelData = [];
        this.productBillAccord = [];
        this.selSupplyProductIndex = -1; //清空单选钮
        this.selSupplyProcessIndex = -1; //清空单选钮
        if (newVal == "0") { //生产单
          this.getProductListSupply(0);
        } else if (newVal == "1") { //工序
          this.getProcessListSupply();
        }
      },
    },
    methods: {
      ...mapActions([
        'getelectronicTemplate', //获取所有单据类型
      ]),

      /* 初始化界面所需数据vuex数据 */
      async initVuextData() {
        //判断单据模板数据是否存在
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }
        //默认获取供选择的生产单列表
        this.getProductListSupply(0);
      },

      /* 获取生产单列表type(0:供选择的  1:根据生产合并查出来的  2:根据工序合并查出来的) */
      getProductListSupply(type) {
        //定义公共数据
        let data = {
          pageIndex: this.operateProduct.currentPage, //当前页
          pageSize: this.operateProduct.pageSize, //页面大小
          delete_flag: 0, //删除标识(0:未作废  1:已作废)
          material_state:'0,1',//领料状态(0:未领料 1:领料中)
          mes_is_merge: '0,1', //合并标识(0:未合并 1:合并中  2:已合并)
          // createUserId: 378, //制单人(需根据权限修改)
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_processMergeBox", 4, '数据加载中,请稍后...');
        //判断查询是左边生产单列表还是右边生产单列表
        if (type == 0) { //左边
          productApi.findMesMainByCondition(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取列表信息
              this.productBillData = res.data.rows.filter(item => item.is_submit >= 4);
              //获取分页信息
              this.operateProduct.totalPage = res.data.total;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else { //右边
          //查询包含当前选择货品的
          if (type == 1) {
            data.product_info_id = this.selSupplyProduct.product_info_id;
          }

          //查询包含当前选择工序的生产单
          if (type == 2) {
            data.mes_process_id = this.selSupplyProcess.id;
          }
          //发送请求
          productApi.findMesMainDetailByCondition(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              // this.productBillAccord = res.data.rows;
              res.data.rows.forEach(itemI => {
                let isMegerArr = []
                itemI.mesProcessList.forEach(itemJ => {
                  if (itemJ.isMerge == 1) {
                    isMegerArr.push(itemJ)
                  }
                })
                if (isMegerArr.length != itemI.mesProcessList.length) {
                  this.productBillAccord.push(itemI)
                }
              })
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 获取工序列表(供选择) */
      getProcessListSupply() {
        let data = {
          pageIndex: this.operateProcess.currentPage, //当前页
          pageSize: this.operateProcess.pageSize, //页面大小
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_processMergeBox", 4, '数据加载中,请稍后...');
        productApi.searchProcessManageById(data).then(res => {
          this.loading.close()
          if (res.code == 200) {
            //获取列表信息
            this.productProcessData = res.data.rows;
            //获取分页信息
            this.operateProcess.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取当前选择的数据(供选择) type(0:供选择的生产计划单列表 1:供选择的工序列表)*/
      getSelectedSupply(index, data, type) {
        //清空列表数据
        this.processData = [];
        this.productSelData = [];
        this.productBillAccord = [];
        if (type == 0) { //生产单
          //获取当前选择的生产单下标
          this.selSupplyProductIndex = index;
          //获取当前选择的生产单数据
          this.selSupplyProduct = data;
          //获取右侧生产单列表
          this.getProductListSupply(1);
        } else if (type == 1) { //工序
          //获取当前选择的工序下标
          this.selSupplyProcessIndex = index;
          //获取当前选择的生产单数据
          this.selSupplyProcess = data;
          //获取右侧生产单列表
          this.getProductListSupply(2);
        }
      },

      /* 获取已合并和未合并工序 data:当前行数据 type(0:气泡 1:单元格) merge(0:未合并 1:已合并)  */
      getMergeNum(data, type, merge) {
        let isMergeName = [];
        let isMerge = [];
        if (merge == 1) { //已合并
          isMerge = data.mesProcessList.filter(item => item.isMerge == 1)
        } else if (merge == 0) { //未合并
          isMerge = data.mesProcessList.filter(item => item.isMerge == 0)
        }
        if (type == 0) { //气泡
          isMerge.forEach(item => {
            isMergeName.push(item.name)
          })
          return isMergeName.length == 0 ? false : isMergeName //气泡显示
        }
        if (type == 1) { //单元格
          return isMerge.length + ' 条工序' //单元格显示
        }
      },

      /* 获取分页大小发生改变 type(0:供选择的生产计划单列表 1:供选择的工序列表) */
      getOperatePageSize(val, type) {
        if (type == 0) {
          //将数据存入列表
          this.operateProduct.pageSize = val;
          //获取供选择的生产单列表
          this.getProductListSupply(type);
        } else if (type == 1) {
          //将数据存入列表
          this.operateProcess.pageSize = val;
          //获取供选择的工序列表
          this.getProcessListSupply()
        }
      },

      /* 获取当前页发生变化 type(0:供选择的生产计划单列表 1:供选择的工序列表) */
      getOperateCurrentPage(val, type) {
        if (type == 0) {
          //将数据存入列表
          this.operateProduct.currentPage = val
          //获取供选择的生产单列表
          this.getProductListSupply(type);
        } else if (type == 1) {
          //将数据存入列表
          this.operateProcess.currentPage = val;
          //获取供选择的工序列表
          this.getProcessListSupply()
        }
      },

      /* 获取所选需要合并的生产单(全选时) */
      getSelNeedProductBillAll(selection) {
        if (selection.length == 0) {
          this.processData = [];
          this.productSelData = [];
          return
        }
        selection.forEach(item => {
          this.getSelNeedProductBill(selection, item)
        })
      },

      /* 获取所选需要合并的生产单 */
      getSelNeedProductBill(selection, row) {
        //判断是选中还是取消
        if (selection.indexOf(row) == -1) { //取消
          //删除对应数据
          this.productSelData = this.productSelData.filter(item => item.selProductBillId != row.id);
        } else { //选中
          // 判断生产单是否设置工序
          if (row.mesProcessList.length > 0) {
            //定义接受未指定物料的工序数组
            let notAssignMaterialProcess = [];
            //循环工序判断是否有未指定物料的工序
            row.mesProcessList.forEach((itemI, indexI) => {
              //判断是哪种合并
              if (this.operateType == 0) { //按生产单合并(判断所有工序都需指定物料)
                //判断工序是否指定物料
                if (itemI.assignMaterialList.length == 0) {
                  notAssignMaterialProcess.push(itemI.name);
                }
              } else if (this.operateType == 1) { //按工序合并(判断指定工序都需指定物料)
                //判断工序是否指定物料
                if (itemI.processMainId == this.selSupplyProcess.id && itemI.assignMaterialList.length == 0) {
                  notAssignMaterialProcess.push(itemI.name);
                }
              }
            })
            //判断是否有未设置工序的物料
            if (notAssignMaterialProcess.length > 0) {
              this.$message({
                type: 'warning',
                message: '该生产单中【' + notAssignMaterialProcess.join(",") + '】工序未指定物料,请确认!',
                duration: this.elDuration
              })
              return
            }
            //获取数据
            row.mesProcessList.forEach((itemI, indexI) => {
              //判断当前合并类型
              if (this.operateType == 0) { //按生产单合并(合并全部工序)
                itemI.assignMaterialList.forEach((itemJ, indexJ) => {
                  let selItem = {
                    selMaterial: itemJ,//物料数据
                    selProcess: itemI,//工序数据
                    selProductBill: row,//生产单数据
                    selProcessId:itemI.processMainId,//工序id
                    selProductBillId:row.id,//生产单id
                  }
                  //将数据添加到data
                  this.productSelData.push(selItem);
                })
              } else if (this.operateType == 1) { //按工序合并(只合并指定工序)
                if (itemI.processMainId == this.selSupplyProcess.id) {
                  itemI.assignMaterialList.forEach((itemJ, indexJ) => {
                    let selItem = {
                      selMaterial: itemJ,
                      selProcess: itemI,
                      selProductBill: row
                    }
                    //将数据添加到data
                    this.productSelData.push(selItem);
                  })
                }
              }
            })
          } else {
            this.$message({
              type: 'warning',
              message: '该生产单未设置工序,请确认!',
              duration: this.elDuration
            })
          }
        }
        console.log(this.productSelData);
      },

      /* 合并工序 type(1:提交审批 2:保存并审批) */
      mergeProcessData(type) {
        //定义相关接受参数
        let mesProcess = [];//合并工序后的数据
        let mesExitProcessId = [];//已经存在的工序id数组
        let mesMainId = [];//已存在的生产单id数组
        let mesMainNo = [];//已存在的生产单编号数组
        let mesMainNum = [];//已存在的生产单计划数量数组
        //拷贝一份新的数据
        let productSelDataNew = JSON.parse(JSON.stringify(this.productSelData));
        //循环处理工序
        productSelDataNew.forEach((item,index)=>{
          let curItem = JSON.parse(JSON.stringify(item));
          //判断工序是否已存在
          if(mesExitProcessId.includes(curItem.selProcessId)){
            //循环处理工序数据
            mesProcess.forEach((itemJ,indexJ)=>{
              if(itemJ.processMainId == curItem.selProcessId){
                //获取生产单相关信息
                if(!mesMainId.includes(curItem.selProductBill.id)){
                  mesMainId.push(curItem.selProductBill.id);//生产单id
                  mesMainNo.push(curItem.selProductBill.bills_no);//生产单编号
                  mesMainNum.push(curItem.selProductBill.plan_num);//生产单计划数量
                }
                // 获取源工序相关信息
                if(!itemJ.originalProcessId.includes(curItem.selProcess.id)){
                  itemJ.originalProcessId.push(curItem.selProcess.id);

                  //获取初始已有的物料数据
                  let materialExitId = [];
                  itemJ.assignMaterialList.forEach((itemK,indexK)=>{
                    materialExitId.push(itemK.productInfoId);
                  })
                  //循环处理工序指定物料
                  itemJ.assignMaterialList.forEach((itemK,indexK)=>{
                    curItem.selProcess.assignMaterialList.forEach((itemZ,indexZ)=>{
                      if(materialExitId.includes(itemZ.productInfoId)){
                        if(itemK.productInfoId == itemZ.productInfoId){
                          console.log(itemK.needNum);
                          //计算物料数量
                          itemK.needNum = itemK.needNum + itemZ.needNum;
                          itemK.needAllNum = itemK.needAllNum + itemZ.needAllNum;
                        }
                      }else{
                        materialExitId.push(itemZ.productInfoId);
                        itemK.push(itemZ);
                      }
                    })
                  })
                  //总工价
                  itemJ.totalLabourCost += curItem.selProcess.totalLabourCost;
                }
              }
            })
          }else{
            //将工序id存入
            mesExitProcessId.push(curItem.selProcessId);
            //判断生产单id是否存在
            if(!mesMainId.includes(curItem.selProductBill.id)){
              mesMainId.push(curItem.selProductBill.id);
              mesMainNo.push(curItem.selProductBill.bills_no);
              mesMainNum.push(curItem.selProductBill.plan_num);
            }
            //将工序信息存入
            let itemProcess = curItem.selProcess;
            itemProcess.originalProcessId = [curItem.selProcess.id];//原工序id
            //添加处理好的数据
            mesProcess.push(itemProcess);
          }
          console.log("------------------------");
        })

        //设置工序物料
        mesProcess.forEach((item,index)=>{
          item.assignMaterial = item.assignMaterialList;
          item.mesMainId = mesMainId.join(",");//生产计划单id
          item.mesMainNo = mesMainNo.join(",");//生产计划单编号
          item.mesMainNum = mesMainNum.join(",");//生产计划单计划数量
          item.originalProcessId = item.originalProcessId.join(",")
        })
        console.log(mesProcess);
        //定义后端请求数据
        let data = {
          mesProcess: JSON.stringify(mesProcess) //转json
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_processMergeBox", 4, '数据添加中,请稍候...');
        //发送请求
        productApi.addMesProcess(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            let billingName = this.commonJsExtend.getBillsDataByType(2, 'DOC-M-016', this.billTemplateData)
              .bills_type //获取单据类型名称
            if (type == 1) {
              //定义传入后端的值
              let submitData = {
                billingName, //单据类型名称
                isSubmit: 1, //状态
                mesProcessIds: res.data.toString(), //生产执行单id
                processId: 313,
                statusId: 84, //节点id
              }
              productApi.updateMesProcessSubmit(submitData).then(result => {
                if (result.code == 200) {
                  //关闭合并工序弹框
                  this.closeMargeProcessBox();
                  //刷新工序单列表页数据
                  this.$parent.getMesProcessList();
                } else {
                  this.$message({
                    type: 'error',
                    message: result.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (type == 2) {
              //定义传入后端的值
              let submitData = {
                //基本数据
                billingName, //单据类型名称
                mesProcessIds: res.data.toString(), //生产执行单id
                processId: 313,
                statusId: 84, //节点id
              }
              productApi.updateMesProcessStatusYes(submitData).then(result => {
                if (result.code == 200) {
                  //关闭合并工序弹框
                  this.closeMargeProcessBox();
                  //刷新工序单列表页数据
                  this.$parent.getMesProcessList();
                } else {
                  this.$message({
                    type: 'error',
                    message: result.message,
                    duration: this.elDuration
                  })
                }
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 关闭合并工序弹框 */
      closeMargeProcessBox() {
        this.$parent.closeMargeBox();
      },
    },
  }
</script>
<!-- element ui自定义样式 -->
<style>
  /* el-tab样式 */
  .margeBoxAll .el-tabs--border-card>.el-tabs__content {
    padding: 5px;
  }

  .margeBoxAll .el-radio__label {
    padding-left: 0px;
  }
</style>

<style lang="less">
  // 工序合并自定义头部内容
  .processMargeDialog {
    .title {
      font-size: 16px;
      padding-right: 10px;
    }
  }

  // 工序合并总框
  .margeBoxAll {
    height: calc(100vh - 140px);
    width: 100%;

    // border: 1px solid black;
    //操作框
    .margeBoxTop {
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: space-between;

      // border: 1px solid black;
      //左侧操作方式框
      .margeBoxLeft {
        height: 100%;
        width: calc(50% - 5px);

        // border: 1px solid black;
        //操作方式内容框
        .operateItemBox {
          height: 37.2vh;

          // border: 1px solid black;
          //列表
          .operateList {
            height: calc(100% - 35px);
            overflow: auto;
            // border: 1px solid black;
          }

          //分页
          .operatePage {
            height: 35px;
            border: 1px solid #DCDFE6;
            // border: 1px solid black;
          }
        }
      }

      //右边操作内容框
      .margeBoxRight {
        height: 100%;
        width: calc(50% - 5px);
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
        border: 1px solid #DCDFE6;

        //头部提示信息
        .rightHead {
          height: 38px;
          background: #f5f7fa;
          border-bottom: 1px solid #E4E7ED;
          // border: 1px solid black;
        }

        //内容
        .rightBody {
          height: calc(100% - 50px);
          padding: 5px;
          overflow: auto;
          // border: 1px solid black;
        }

        //合并单元格
        .merge {
          color: #0091ff;
          font-weight: 600;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    //列表框
    .margeBoxContent {
      height: calc(50% - 12px);
      width: 100%;
      margin-top: 10px;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
      border: 1px solid #DCDFE6;

      //头部提示信息
      .contentHead {
        height: 38px;
        background: #f5f7fa;
        border-bottom: 1px solid #E4E7ED;
        // border: 1px solid black;
      }

      //表格内容
      .contentBody {
        height: calc(100% - 40px);
        overflow: auto;
        padding: 5px;
        // border: 1px solid black;
      }
    }

    /* 标题提示信息 */
    .titleTip {
      width: 100%;
      line-height: 38px;
      font-weight: bold;
      color: @theme;
      font-size: 16px;
      text-align: center;
      // border: 1px solid black;
    }
  }
</style>
